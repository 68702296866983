// import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

// import { FLUTTER_TRACKING } from '@/services/flutter';

export interface IFlutter {
  my_id: string;
}

export const LOCAL_STORAGE_FLUTTER_KEY = 'local-storage-flutter-key';

export const useFlutterWebview = () => {
  const [data, setData] = useState(() => {
    const defaultValue = { my_id: '' };
    try {
      const value = window.localStorage.getItem(LOCAL_STORAGE_FLUTTER_KEY);

      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(
          LOCAL_STORAGE_FLUTTER_KEY,
          JSON.stringify(defaultValue)
        );
        return defaultValue;
      }
    } catch {
      return defaultValue;
    }
  });

  useEffect(() => {
    window.addEventListener('message', function (event) {
      // NOTE: need to send my_id fields
      if (!event.data.my_id) return;
      try {
        window.localStorage.setItem(
          LOCAL_STORAGE_FLUTTER_KEY,
          JSON.stringify(event.data)
        );
        setData(event.data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    });
  }, []);

  // NOTE: send data from webview to flutter
  // const router = useRouter();
  // useEffect(() => {
  //   const onRouteChangeComplete = (url) => {
  //     FLUTTER_TRACKING(url);
  //   };
  //   router.events.on('routeChangeComplete', onRouteChangeComplete);
  //   return () => {
  //     router.events.off('routeChangeComplete', onRouteChangeComplete);
  //   };
  // }, [router]);

  return data;
};
